<script lang="ts">
export default {
    name: "PageResolver",
};
</script>

<script setup lang="ts">
import {Ref, resolveComponent} from "vue";
import {pascalCase} from "scule";
import {
    useNavigationContext,
    useNavigationSearch,
} from "@shopware-pwa/composables-next";
import {SeoUrl} from "@shopware-pwa/types";
import useRedirect from "~/composables/useRedirect";

const {clearBreadcrumbs} = useBreadcrumbs();
const NOT_FOUND_COMPONENT = "errors/RoutingNotFound";
const {resolvePath} = useNavigationSearch();
const route = useRoute();

const {data: seoResult} = await useAsyncData(
    "cmsResponse" + route.path,
    async () => {
        if (process.client) {
            if (history.state?.routeName) {
                return {
                    routeName: history.state?.routeName,
                    foreignKey: history.state?.foreignKey,
                };
            }
        }
        return await resolvePath(route.path);
    }
);

// onBeforeRouteLeave(() => {
//     clearBreadcrumbs();
// });

const {navigationContext, routeName, foreignKey} = useNavigationContext(
    seoResult as Ref<SeoUrl>
);

const redirectToUrl = async function(url: string, redirectCode: number) {
    if (process.client) {
        navigateTo(url, {redirectCode: redirectCode, external: true});
    }
    if (process.server) {
        const event = useRequestEvent();
        await sendRedirect(event, url, redirectCode);
    }
}

function render() {
    const componentName = routeName.value;
    if (!componentName) {
        if (process.server) {
            const event = useRequestEvent();
            setResponseStatus(event, 404);
        }

        return h("div", h(resolveComponent(pascalCase(NOT_FOUND_COMPONENT))));
    }

    if (navigationContext.value && navigationContext.value.seoPathInfo && '/' + navigationContext.value.seoPathInfo != route.path) {
        useRedirect("/" + navigationContext.value.seoPathInfo, 301);
        return;
    }

    const componentNameToResolve = pascalCase(componentName as string);
    const cmsPageView = routeName && resolveComponent(componentNameToResolve);
    if (cmsPageView) {
        if (cmsPageView === componentNameToResolve)
            return h("div", {}, "Problem resolving component: " + componentName);
        return h("div", h(cmsPageView, {navigationId: foreignKey.value}));
    }
    return h("div", {}, "Loading...");
}
</script>

<template>
<render/>
</template>
